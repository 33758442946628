<template>
  <div id="Contrast">
    <div :class="$store.state.lang == 'en' ? 'english' : ''">
      <Header :info="info"></Header>

      <!-- 选项区 -->
      <div class="C_Title">
        <div class="Page_title">{{ $t(`message.Contrast.Title`) }}
          <div class="line"></div>
        </div>
        <div class="bar_One">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
          >
            <el-form-item
              :label="$t(`message.Contrast.item`)"
              class="bar_One_text_1"
            >
              <el-select
                v-model="formInline.product"
                :placeholder="$t(`message.Contrast.select`)"
              >
                <el-option
                  v-for="(item, index) in program_name"
                  :key="index"
                  :label="item.project_name + '/' + item.category"
                  :value="item.project_name"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="$t(`message.Contrast.products`)"
              class="bar_One_text_2"
            >
              <el-select
                v-model="formInlines.product"
                :placeholder="$t(`message.Contrast.select1`)"
              >
                <el-option
                  v-for="(item, index) in product_name"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>

        <div class="block">
          <span class="demonstration">{{ $t(`message.Contrast.compare`) }}</span>
          <el-cascader
            :placeholder="$t(`message.Contrast.select2`)"
            :options="options_2"
            v-model="contrast"
            :value="values"
            :show-all-levels="false"
            :props="{ multiple: true }"
            popper-class="location"
            filterable
            clearable
            ref="cascader"
            @change="handleChange"
          >
            <template slot-scope="{ data }">
              {{ data.label }}
              <span
                v-if="contrast.length >= 10"
                class="exceeded-overlay"
                @click="handleSelectionExceeded"
              ></span>
            </template>
          </el-cascader>
        </div>

        <div
          class="C_button"
          v-show="$store.state.lang == 'cn'"
        >
          <img
            src="https://newstore.vynior.com/dbfx1.png"
            @click="clear_choose"
          />
          <img
            src="https://newstore.vynior.com/dbfx2s.png"
            class="c_item"
            @click="getContrast"
          />
          <img
            src="https://newstore.vynior.com/dpfx.png"
            @click="go_item"
            class="c_export"
          />
        </div>
        <!-- english -->
        <div
          class="C_button"
          v-show="$store.state.lang == 'en'"
        >
          <img
            src="https://newstore.vynior.com/chongzhis.png"
            @click="clear_choose"
            class="reset"
          />
          <img
            src="https://newstore.vynior.com/daochuww.png"
            class="c_item"
            @click="getContrast"
          />
          <img
            src="https://newstore.vynior.com/tiaozhuandd.png"
            @click="go_item"
            class="c_export"
          />
        </div>
      </div>

      <!-- 内容大标题  感官属性数据/对比分析结果-->
      <div class="C_content_title">
        <div class="title_name">
          <img
            src="https://newstore.vynior.com/dbfx3.png"
            alt=""
          />
          <span>{{ $t(`message.Contrast.box1Title`) }}</span>
        </div>

        <div class="title_result">
          <img
            src="https://newstore.vynior.com/dbfx4.png"
            class="title_result_img1"
          />
          <span>{{ $t(`message.Contrast.box2Title`) }}</span>
          <el-tooltip
            class="item"
            :open-delay="500"
            effect="dark"
            placement="bottom"
            popper-class="back_ground"
          >
            <div
              slot="content"
              class="chazhi"
            >{{ $t(`message.Tooltip.Difference`) }}</div>
            <img
              src="https://newstore.vynior.com/ana3.png"
              class="title_result_img2"
            />
          </el-tooltip>
        </div>
      </div>

      <!-- 数据盒子 -->
      <div class="data_max_box">
        <div class="data_box">
          <!-- 左侧盒子 -->
          <div
            class="main_product_box"
            ref="scroll_left"
            @scroll="scrollEvents($event)"
          >
            <div class="main_data_name">
              <span class="importent_name">{{ $t(`message.Contrast.Importance`) }}</span>
              <span class="importent_mc">{{ $t(`message.Contrast.AttributeName`) }}</span>
              <span class="importent_data">{{ $t(`message.Contrast.AttributeData`) }}</span>
              <el-tooltip
                :open-delay="500"
                class="item"
                effect="dark"
                :content="choose_product"
                placement="top"
                popper-class="back_ground"
                v-if="formInlines.product != 0 && formInlines.product != null"
              >
                <span class="importent">{{ this.choose_product }}</span>
              </el-tooltip>
            </div>
            <!--循环数据main盒子数据-->
            <div v-if="formInlines.product != 0 && formInlines.product != null">
              <div
                class="main_data_detail"
                :class="index == 0 ? 'fist_data' : ''"
                v-for="(item, index) in map_arr"
                :key="index"
              >
                <div class="data_detail_img">
                  <img
                    :src="imgUrl[item.importtant]"
                    alt=""
                  />
                </div>

                <div class="data_detail_name">
                  <!-- <span>属性名称{{ index + 1 }}</span> -->
                  <span v-if="$store.state.lang=='cn'">{{ item.name[0]}}<br>{{item.name_end[0]}}</span>
                    <span v-if="$store.state.lang=='en'">{{ item.name_en[0]}}<br>{{item.name_en_end[0]}}</span>
                  
                  
                </div>

                <!-- 数值dot -->
                <div class="dot_num">
                  <span>{{ parseFloat(item.data).toFixed(1) }}</span>
                </div>

                <!-- 数据轴线 -->
                <div class="FD_Axis">

                  <div class="axis_shadow">
                    <!-- 蓝色数据条 -->
                    <!-- 进度条 -->

                    <div
                      :style="{
                        width: `${(parseFloat(item.data) / 15).toFixed(2) * 100}%`
                      }"
                      class="un_xg_1_3_1"
                    ></div>
                  </div>

                  <div class="axios_val">
                    <span class="axios_min">0</span>
                    <span class="axios_max">15</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="left_box"
              v-if="this.formInlines.product == 0 || this.formInlines.product == null"
            >
              <div class="left_img">
                <img
                  src="https://newstore.vynior.com/compare_1.png"
                  alt=""
                />
              </div>
              <div class="left_text">
                <span> {{ $t(`message.Contrast.NoAttribute`) }}</span>
              </div>
            </div>
          </div>

          <!-- 左侧空数据盒子 -->

          <!-- <div class="main_product_boxs" v-if="this.formInlines.product == 0 || this.formInlines.product == null">
          <div class="left_box">
            <div class="left_img">
              <img src="https://newstore.vynior.com/compare_1.png" alt="" />
            </div>
            <div class="left_text">
              <span> 暂无属性数据</span>
            </div>
          </div>
        </div> -->

          <!-- 右侧盒子 -->
          <div class="choose_product_box">
            <!-- 数据名称盒子 -->

            <!-- 数据盒子 -->
            <div
              class="choose_product_boxs"
              ref="scroll_right"
              @scroll="scrollEvent($event)"
              v-if="choose_name.length > 0"
            >
              <!-- 样式盒子1 -->

              <div
                class="box1"
                v-for="(item, index) in choose_name"
                :key="index"
                :class="index == 0 ? 'fist_box' : ''"
              >
                <!--循环数据盒子的名字  -->
                <div
                  class="choose_title"
                  id="name-box"
                  :style="{ marginLeft: '-' + Xoffset + 'px' }"
                >
                  <div class="name">
                    <div style="width: auto; max-width: 144px">
                      <el-tooltip
                        :open-delay="500"
                        class="item"
                        effect="dark"
                        :content="choose_label[index] + '-' + item"
                        placement="top"
                        popper-class="back_ground"
                      >
                        <span class="item_name_content">{{ item }}</span>
                      </el-tooltip>
                    </div>
                  </div>
                  <span
                    class="middle_title"
                    @click="show"
                  >{{ $t(`message.Contrast.Difference`) }}</span>
                  <!-- <span>差值比例</span> -->
                </div>
                <!-- 数据展示 -->
                <div class="choose_data">
                  <!-- 数据轴线 -->
                  <div
                    class="FD_Axis"
                    v-for="(ite, inde) in dataArray"
                    :key="inde"
                    :class="inde == product_message.paneldata.length - 1 ? 'clear_bottom' : ''"
                  >
                    <div class="axis_shadow">
                      <!-- 进度条 -->
                      <div
                        :style="{
                          width: `${((resultArr[index][inde].data - ite) / 15).toFixed(2) * 50}%`,
                        }"
                        class="un_xg_1_3_1"
                        v-if="resultArr[index][inde].data - ite > 0"
                      ></div>

                      <!-- 红条 -->
                      <div
                        :style="{
                          width: `${(((resultArr[index][inde].data - ite) / 15) * -1).toFixed(2) * 50}%`,
                          leftStyle,
                        }"
                        class="un_xg_1_3_1_s"
                        v-if="resultArr[index][inde].data - ite < 0"
                      ></div>
                      <!-- 灰色条 -->
                      <div
                        :style="{
                          width: `${1}%`,
                          leftStyle,
                        }"
                        class="un_xg_1_3_1_0"
                        v-show="resultArr[index][inde].data - ite == 0"
                      ></div>
                      <!-- 数值dot -->
                      <div
                        class="dot_num"
                        v-if="resultArr[index][inde].data - ite > 0"
                      >
                        <span
                          class="dot_num_span"
                          :style="{
                            left: `${Math.abs(((resultArr[index][inde].data - ite) / 15) * -1).toFixed(2) * 50 + 45}%`,
                            leftStyle,
                          }"
                        >{{ '+' + (resultArr[index][inde].data - ite).toFixed(1) }}</span>
                      </div>
                      <!-- 负数的状况 -->
                      <div
                        class="dot_num"
                        v-if="resultArr[index][inde].data - ite < 0"
                      >
                        <span
                          class="dot_num_span_1"
                          :style="{
                            left: `${Math.abs(45 - ((resultArr[index][inde].data - ite) / 15) * -(1).toFixed(2) * 50)}%`,
                            leftStyle,
                          }"
                        >{{ (resultArr[index][inde].data - ite).toFixed(1) }}</span>
                      </div>
                      <!-- 0的状况 -->
                      <div
                        class="dot_num"
                        v-if="resultArr[index][inde].data - ite == 0"
                      >
                        <span class="dot_num_span_0"> 0</span>
                      </div>

                      <span></span>
                    </div>

                    <div
                      class="axios_val"
                      v-if="0"
                    >
                      <span class="axios_min">-15</span>
                      <span class="axios_middle">0</span>
                      <span class="axios_max">15</span>
                    </div>
                    <div
                      class="difference_val"
                      v-if="Products[0].panel_attributes_data[index] - item > 0"
                    ></div>

                    <div
                      class="difference_val"
                      v-if="Products[0].panel_attributes_data[index] - item < 0"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 右侧空数据盒子 -->
            <div
              class="choose_product_boxs_empty"
              v-show="choose_name.length == 0"
            >
              <div class="right_box">
                <div class="right_img">
                  <img
                    src="https://newstore.vynior.com/compare_2.png"
                    alt=""
                  />
                </div>
                <div class="right_text">
                  <span> {{ $t(`message.Contrast.NoAttribute`) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from '@/utils/resetMessage';

import { HttpClient } from '@/utils/http';
import Header from '@/components/Header';
import getFilename from '../../utils/getFilename';
export default {
  name: 'Contrast',
  components: {
    Header,
  },
  data() {
    return {
      values: '',
      contrast:"",
      info: 5,
      Yoffset: 0,
      Xoffset: 0, //x轴的距离
      sort_arr: [], //存储排序后的数组
      count_arr: [], //处理合并后的数组【{}，{}】
      attribute_view: [], //处理重要性图片
      attribute_data: [], //接收感官数据维度和重要性
      choose_product: '', //选择的产品名称
      product_message: [], //选择的项目信息
      compare_i_message: [], //对比产品的信息
      compare_id: [], //对比产品的id
      product_id: '', //选择的产品id
      product_name: [], //产品请求返回信息
      choose_pj_name: '', //选择的项目名称
      program_name: [], //项目请求返回信息
      resultArr: [], //对比的全部数据
      // 禁用多选框
      disabled_val: '',
      map_arr: [], //全部的数据
      dataArray: [], //全部数据的data
      choose_name: [], // 勾选对比产品的名字
      choose_label: [], // 勾选对比产品的项目名字
      // 选择数据拆分
      product_new: [],
      product_news: [],
      formInline: { region: '', product: '' },
      formInlines: { region: '', product: '' },
      options_model: { value: '', label: '' }, //选择对比数据的增加模板
      options: [],
      options_2: [],
      project_id: '',
      title: '对应的信息',
      FD_Axis: [
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx6.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx7.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 11,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx6.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx6.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx6.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx7.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 4.8,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 4.8,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 14,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 13.5,
        },
      ],
      FD_Axiss: [
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 5,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 4,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 8,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 11,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 10,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 9,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx6.png',
          progress_width: 4,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx6.png',
          progress_width: 5,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx6.png',
          progress_width: 3,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx7.png',
          progress_width: 9,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 6,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 7,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 9,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 11,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 3,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 11,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 7,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 11,
        },
      ],
      FD_Axisss: [
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 5,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 4,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 8,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 11,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 10,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          progress_width: 9,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx6.png',
          progress_width: 4,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx6.png',
          progress_width: 5,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx6.png',
          progress_width: 3,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx7.png',
          progress_width: 9,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 6,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 7,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 9,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx8.png',
          progress_width: 11,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 3,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 11,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 7,
        },
        {
          img_url: 'https://newstore.vynior.com/dbfx9.png',
          progress_width: 11,
        },
      ],
      fd_cha: [],

      // // 产品
      Products: [
        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC1', //产品_Id
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ', //项目_Id
          project_name: 'FS-066', //项目编号
          name: 'Prototype 1',
          project_data: '2022/10/8', //创建日期
          classification: '竞品', //产品品类
          category: '防晒', //产品分类
          product_number: '300303', //产品编号
          color: '白色', //产品颜色
          product_place: '中国', //产地
          brand: 'PPP', //品牌
          quality: '未知', //质地
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '', //备注
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          panel_attributes_data: [
            6.8, 4.7, 6.7, 8.8, 8.5, 6.9, 7.8, 3.7, 6.8, 6.3, 7.5, 6.5, 5.8, 7.3, 6.4, 9.1, 8.7, 7.4, 6.1, 8.3, 7.6, 6.9, 8.6, 6.8, 6.4, 7.0, 6.8,
            6.2, 7.5, 6.5, 8.1, 4.8, 4.1, 5.6, 2.4, 10.6, 1.1, 5.7, 4.9, 4.1, 5.6,
          ], //感官数据
          overall_data: 3.0, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], // 整体95%置信区间阈值
          overall_5: [0.2, 0.3, 0.3, 0.1, 0.1], // 整体五种分数打分占比
          overalln_5: [
            {
              num: 3.33,
              name: '质地',
              percent: 94,
            },
            {
              num: 4.61,
              name: '涂抹度',
              percent: 96,
            },
            {
              num: 4.72,
              name: '吸收程度',
              percent: 91,
            },
            {
              num: 1.88,
              name: '颜色',
              percent: 95,
            },
            {
              num: 4.83,
              name: '光泽度',
              percent: 99,
            },
          ],
          prediciton_data: [4.4, 4.4, 3.9, 3.3, 3.2, 4.2, 3.5, 4.4, 4.4, 3.7, 3.1, 4.6, 4.6, 3.7, 3.7], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC2', //产品_Id
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ', //项目_Id
          project_name: 'FS-066',
          project_data: '2022/10/8', //创建日期
          name: 'Prototype 2',
          classification: '样品',
          category: '防晒',
          product_number: '333',
          color: '透明',
          product_place: '法国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '3.6', //光泽
          remark: '',
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          panel_attributes_data: [
            4.7, 5.7, 2.4, 5.9, 7.1, 4.0, 6.8, 2.7, 6.6, 6.1, 7.4, 5.4, 4.7, 6.6, 1.7, 6.3, 9.4, 5.8, 4.7, 6.8, 6.4, 5.6, 7.5, 6.9, 6.9, 6.9, 6.9,
            6.2, 7.5, 8.6, 9.7, 5.1, 4.2, 6.3, 1.9, 5.6, 5.5, 7.1, 5.8, 4.7, 6.5,
          ],
          overall_data: 3.2, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.25, 0.2, 0.35, 0.1, 0.1], //整体五种分数打分占比
          prediciton_data: [4.2, 4.2, 4.6, 4.5, 3.7, 4.5, 3.5, 4.2, 4.0, 3.2, 3.7, 4.5, 4.6, 3.2, 3.2], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81],
          //消费者维度预测分数准确率,
        },

        {
          product_id: ' X3tucj8LYuBo638gq6pQOpC3', //产品_Id
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ', //项目_Id
          project_name: 'FS-066',
          project_data: '2022/10/8', //创建日期
          name: 'Prototype 3',
          classification: '样品',
          category: '防晒',
          product_number: '2445',
          color: '白色',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '7.9', //光泽
          remark: '',
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          panel_attributes_data: [
            3.5, 4.5, 2.4, 5.7, 10.8, 3.1, 7.8, 2.9, 7.0, 6.4, 7.7, 6.5, 5.8, 7.3, 1.8, 4.9, 10.7, 6.0, 5.3, 6.8, 7.2, 6.4, 8.3, 7.9, 7.8, 8.6, 7.2,
            6.6, 7.8, 7.7, 10.1, 4.3, 3.8, 5.1, 1.9, 4.8, 4.4, 6.3, 5.2, 4.4, 5.9,
          ],
          overall_data: 3.4, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.27, 0.15, 0.25, 0.23, 0.1], //整体五种分数打分占比
          prediciton_data: [3.8, 3.9, 3.2, 3.9, 4.3, 3.7, 3.1, 3.5, 3.6, 4.5, 3.9, 3.8, 3.5, 4.6, 4.3], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },
        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC4', //产品_Id
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ', //项目_Id
          project_name: 'FS-066',
          project_data: '2022/10/8', //创建日期
          name: 'Prototype 4',
          classification: '市售产品',
          category: '防晒',
          product_number: '325',
          color: '白色',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          panel_attributes_data: [
            7.1, 5.4, 2.1, 7.0, 10.0, 5.4, 6.8, 2.3, 6.5, 5.9, 7.3, 5.5, 4.8, 6.5, 2.1, 7.8, 9.7, 5.3, 4.5, 6.7, 6.1, 5.4, 7.4, 7.1, 7.1, 7.0, 6.6,
            5.9, 7.4, 8.4, 9.1, 5.0, 4.1, 6.1, 1.8, 8.0, 5.7, 6.8, 5.3, 4.5, 6.2,
          ],
          overall_data: 3.62, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.27, 0.31, 0.24, 0.1, 0.08], //整体五种分数打分占比
          prediciton_data: [3.7, 3.4, 3.8, 3.1, 3.4, 3.8, 4.5, 3.6, 4.1, 3.6, 3.1, 3.3, 3.0, 3.3, 3.9], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC5', //产品_Id
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ', //项目_Id
          project_name: 'FS-066',
          project_data: '2022/10/8', //创建日期
          name: 'Prototype 5',
          classification: '市售产品',
          category: '防晒',
          product_number: '555',
          color: '透明',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          panel_attributes_data: [
            3.3, 6.3, 2.3, 2.3, 10.8, 3.0, 7.4, 6.3, 7.3, 6.8, 7.9, 6.4, 5.8, 7.0, 5.4, 6.5, 10.5, 6.3, 5.7, 7.3, 7.4, 6.6, 8.2, 6.5, 6.4, 6.6, 7.2,
            6.7, 7.8, 7.5, 9.3, 5.8, 5.0, 6.8, 4.3, 5.9, 4.1, 6.8, 5.5, 4.7, 6.3,
          ],
          overall_data: 3.75, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.37, 0.21, 0.24, 0.1, 0.08], //整体五种分数打分占比
          prediciton_data: [4.2, 3.7, 4.2, 4.0, 4.4, 3.2, 3.8, 3.5, 4.3, 3.3, 3.9, 3.0, 3.9, 4.6, 3.2], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC6', //产品_Id
          project_id: 'HVRj2kY0O83Bf3q4QdAEeKPg', //项目_Id
          project_name: 'FS-008',
          project_data: '2022/10/8', //创建日期
          name: 'TT Pro 1',
          classification: '竞品',
          category: '防晒',
          product_number: '6343',
          color: '粉丝',
          product_place: '中国',
          brand: 'RQT',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          panel_attributes_data: [
            7.5, 5.7, 0.5, 6.5, 5.3, 5.6, 8.3, 0.6, 7.8, 7.3, 8.5, 7.6, 6.9, 8.0, 3.1, 8.3, 8.3, 7.5, 6.9, 8.4, 8.4, 7.7, 9.4, 7.0, 6.7, 7.6, 7.4,
            6.8, 8.0, 5.3, 9.7, 5.7, 5.1, 6.6, 2.1, 9.0, 8.8, 6.0, 5.1, 4.4, 5.8,
          ],
          overall_data: 3.82, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_: [0.37, 0.3, 0.14, 0.11, 0.08], //整体五种分数打分占比
          prediciton_data: [4.3, 3.6, 4.0, 4.1, 4.2, 3.1, 3.7, 3.3, 4.3, 4.0, 4.6, 3.9, 3.6, 4.3, 4.1], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC7', //产品_Id
          project_id: 'HVRj2kY0O83Bf3q4QdAEeKPg', //项目_Id
          project_name: 'FS-008',
          project_data: '2022/10/8', //创建日期
          name: 'TT Pro 2',
          classification: '样品',
          category: '防晒',
          product_number: '99',
          color: '白色',
          product_place: '美国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          panel_attributes_data: [
            6.3, 5.4, 2.5, 8.3, 11.1, 6.4, 8.5, 2.3, 8.4, 7.7, 9.0, 7.8, 7.2, 8.5, 3.7, 9.4, 10.7, 7.3, 6.7, 8.1, 8.4, 7.7, 9.4, 7.4, 7.2, 7.8, 8.0,
            7.4, 8.7, 6.0, 8.3, 5.8, 5.2, 6.4, 4.4, 8.2, 2.2, 5.9, 5.0, 4.5, 5.6,
          ],
          overall_data: 3.52, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], // 整体95%置信区间阈值
          overall_5: [0.23, 0.3, 0.17, 0.13, 0.17], //整体五种分数打分占比
          prediciton_data: [3.8, 4.3, 3.5, 3.9, 4.5, 3.6, 4.2, 4.2, 3.1, 4.5, 3.5, 3.4, 4.1, 3.7, 3.8], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC8', //产品_Id
          project_id: 'HVRj2kY0O83Bf3q4QdAEeKPg', //项目_Id
          project_name: 'FS-008',
          project_data: '2022/10/8', //创建日期
          name: 'TT Pro 3',
          classification: '样品',
          categor: '防晒',
          product_number: '3434',
          color: '透明',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          panel_attributes_data: [
            3.4, 5.1, 2.7, 5.7, 10.2, 3.2, 7.5, 2.9, 7.3, 6.5, 7.8, 6.4, 5.8, 7.0, 1.9, 4.7, 10.5, 6.3, 5.4, 7.0, 6.5, 5.9, 7.5, 7.3, 6.8, 7.9, 7.5,
            6.8, 8.2, 8.0, 9.6, 5.5, 4.9, 6.0, 2.1, 4.2, 5.4, 6.5, 5.3, 4.6, 5.9,
          ],
          overall_data: 3.02, // 整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.1, 0.2, 0.25, 0.28, 0.17], //整体五种分数打分占比
          prediciton_data: [3.2, 3.3, 3.0, 3.0, 3.2, 3.2, 3.5, 3.1, 3.4, 3.0, 3.0, 3.4, 3.1, 3.3, 3.2], // 消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC9', //产品_Id
          project_id: 'HVRj2kY0O83Bf3q4QdAEeKPg', //项目_Id
          project_name: 'FS-008',
          project_data: '2022/10/8', //创建日期
          name: 'TT Pro 4',
          classification: '市售产品',
          category: '防晒',
          product_number: '66',
          color: '白色',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          panel_attributes_data: [
            6.9, 5.4, 2.9, 7.6, 10.3, 6.2, 8.0, 3.3, 8.1, 7.3, 8.8, 7.2, 6.6, 7.9, 4.3, 9.0, 9.4, 6.8, 6.2, 7.9, 7.3, 6.7, 8.2, 6.2, 5.9, 6.6, 8.0,
            7.3, 8.7, 6.8, 8.7, 7.0, 6.4, 7.7, 4.3, 8.9, 2.2, 6.0, 5.3, 4.7, 6.1,
          ],
          overall_data: 3.13, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], // 整体95%置信区间阈值
          overall_5: [0.1, 0.24, 0.35, 0.18, 0.13], //整体五种分数打分占比
          prediciton_data: [3.1, 3.4, 3.1, 3.2, 3.1, 3.5, 3.5, 3.3, 3.3, 3.4, 3.0, 3.3, 3.5, 3.4, 3.4], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpCA', //产品_Id
          project_id: 'HVRj2kY0O83Bf3q4QdAEeKPg', //项目_Id
          project_name: 'FS-008',
          project_data: '2022/10/8', //创建日期
          name: 'TT Pro 5',
          classification: '市售产品',
          category: '防晒',
          product_number: '34689',
          color: '透明',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          img_url: 'https://newstore.vynior.com/dbfx5.png',
          panel_attributes_data: [
            3.9, 4.8, 4.5, 6.5, 10.6, 3.9, 8.1, 4.8, 7.9, 7.4, 8.8, 7.1, 6.6, 8.0, 2.2, 6.1, 10.4, 7.3, 6.6, 8.4, 7.4, 6.7, 8.3, 6.8, 6.3, 7.2, 7.9,
            7.5, 8.7, 6.5, 8.7, 6.3, 5.7, 7.1, 2.0, 5.2, 2.0, 5.8, 5.3, 4.6, 5.7,
          ], //感官数据默认图表
          overall_data: 3.28, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.15, 0.26, 0.34, 0.13, 0.12], //整体五种分数打分占比
          prediciton_data: [3.7, 3.4, 3.6, 3.7, 3.7, 3.3, 3.5, 3.6, 3.8, 3.0, 3.3, 3.2, 3.2, 3.5, 3.6], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },
      ],
      // 项目详情
      Programs: [
        {
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ',
          project_name: 'FS-066',
          classification: '竞品',
          status: '已保存',
          panel_files: '',
          equipment_files: '',
        },
        {
          project_id: 'HVRj2kY0O83Bf3q4QdAEeKPg',
          project_name: ' FS-008',
          classification: '竞品',
          status: '已保存',
          panel_files: '',
          equipment_files: '',
        },
      ],
      // 图表
      imgUrl: [
        'https://newstore.vynior.com/555.png',

        'https://newstore.vynior.com/44.png',

        'https://newstore.vynior.com/333.png',

        'https://newstore.vynior.com/22.png',

        'https://newstore.vynior.com/11.png',

        'https://newstore.vynior.com/nnnew.png',
      ],
      // 感官数据维度
      panel_attributes_item: [
        '产品弹性',
        '产品清凉感',
        '产品遮盖能力',
        '小垫子',
        '产品质地细腻度',
        '产品软硬程度',
        '产品油感',
        '增白程度',
        '皮肤滋润感（2分钟）',
        '皮肤滋润感（5分钟）',
        '皮肤滋润感（即时）',
        '皮肤油润感（2分钟）',
        '皮肤油润感（5分钟）',
        '皮肤油润感（即时）',
        '蘸起后的峰',
        '蘸起难易程度',
        '产品光泽度',
        '产品残留物的量（2分钟）',
        '产品残留物的量（5分钟）',
        '产品残留物的量（即时）',
        '皮肤光亮程度（2分钟）',
        '皮肤光亮程度（5分钟）',
        '皮肤光亮程度（即时）',
        '皮肤光滑程度（2分钟）',
        '皮肤光滑程度（5分钟）',
        '皮肤光滑程度（即时）',
        '皮肤柔软感（2分钟）',
        '皮肤柔软感（5分钟）',
        '皮肤柔软感（即时）',
        '总体吸收速度',
        '涂抹难易程度',
        '皮肤粘手程度（2分钟）',
        '皮肤粘手程度（5分钟）',
        '皮肤粘手程度（即时）',
        '产品拉丝性',
        '质地厚薄程度',
        '产品透明度',
        '产品水感',
        '皮肤水润感（5分钟）',
        '皮肤水润感（2分钟）',
        '皮肤水润感（即时）',
      ],

      // 默认定位
      leftStyle: '50%',
    };
  },
  watch: {
    'formInline.product': {
      handler(newr, oldr) {
        if (oldr == '') {
          if (newr != oldr) {
            this.choose_pj_name = newr;
            this.product_name = [];
            // this.req_UserProduct();
            // this.formInlines.product = "";
            this.clear_choose();
          }
        } else {
          if (newr != oldr) {
            this.choose_pj_name = newr;
            this.product_name = [];
            this.req_UserProduct();
            this.formInlines.product = '';
            this.clear_choose();
          }
        }
      },
      // immediate: true,
      deep: true,
    },
    'formInlines.product': {
      handler(newr, oldr) {
        // for (let index = 0; index < this.options_2.length; index++) {
        //   this.options[index] = this.options_2[index];
        // }
        // for (let index = 0; index < this.options.length; index++) {
        //   if (this.options[index].label == newr) {
        //     this.options.splice(index, 1);
        //   }
        // }
        if (newr == null) {
          // this.disabled_val = "disabled";
        }
        // else {
        // }
        if (newr != oldr) {
          console.log('chufa');
          this.choose_product = newr;
          this.clear_choose();
          this.req_Product();
          this.checked_product();
          this.compare_i_message = [];
        }
      },
      // immediate: true,
      deep: true,
    },
    choose_name: {
      handler() {},
      deep: true,
    },
    compare_i_message: {
      handler() {},
      deep: true,
    },
  },
  methods: {
    delete_val(val) {
      console.log(val);
      for (let index = 0; index < val; index++) {
        // this.contrast.pop()
        this.compare_id.pop();
        this.choose_name.pop();
        this.choose_label.pop();
      }
      this.req_Comparedatas();
      console.log(this.compare_id);
    },
    // NOTE对比分析导出数据
    async getContrast() {
      let current = this.product_id;
      let contrast = this.compare_id;
      let currentProduct = [];
      for (let item of this.product_message.paneldata) {
        let num = Number(item).toFixed(2);
        currentProduct.push(num);
      }
      currentProduct.unshift(current);
      // console.log("currentProduct :>> ", currentProduct);
      let contrastProduct = [];
      let differenceRatio = [];
      for (let item of this.compare_i_message) {
        let numArr = [];
        let numArr2 = [];
        for (let i = 1; i <= item.paneldata.length; i++) {
          let num = (Number(item.paneldata[i - 1]) - Number(currentProduct[i])).toFixed(1);
          numArr.push(num);
          let num2 = ((Number(num) / Number(currentProduct[i])) * 100).toFixed(0) + '%';
          numArr2.push(num2);
        }
        contrastProduct.push(numArr);
        differenceRatio.push(numArr2);
      }
      contrastProduct.unshift(contrast);
      let response = await HttpClient.post(
        'program/contrastiveDerive',
        {
          currentProduct,
          contrastProduct,
          differenceRatio,
          language:this.$store.state.lang
        },
        { responseType: 'arraybuffer' }
      );
      let name = getFilename(response);
     if(name=="FAST-对比分析数据表.xlsx"&&this.$store.state.lang =="en"){
              name = "FAST-Comparative Analysis.xlsx"
     }

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      let downloadElement = document.createElement('a');
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = `${name}`;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      // downloadElement.addEventListener('beforeunload',e=>{})
      window.URL.revokeObjectURL(href);
    },
    // 获取滚动条的距离（对比盒子）
    scrollEvent(e) {
      // console.log(e);
      // //滚动条 距离 头部的距离
      // console.log(e.target.scrollTop);
      // //滚动条 距离 左侧的距离
      // console.log(e.target.scrollLeft + 'px', '距离左边的宽度');
      this.Xoffset = e.target.scrollLeft;
      this.Yoffset = e.target.scrollTop;
      this.$refs.scroll_left.scrollTop = this.$refs.scroll_right.scrollTop;
    },
    scrollEvents() {
      // e.target.scrollTop = this.Yoffset
      this.$refs.scroll_right.scrollTop = this.$refs.scroll_left.scrollTop;
      // console.log(e.target.scrollTop, "left");
    },
    show() {
      // for (let index = 0; index < this.FD_Axis.length; index++) {
      //   this.fd_cha[index] = (
      //     this.FD_Axis[index].progress_width -
      //     this.FD_Axiss[index].progress_width
      //   ).toFixed(0);
      //   // console.log(sum);
      //   console.log(111);
      // }
    },
    // 清除选中的节点
    clear_choose() {
      let obj = {};
      obj.stopPropagation = () => {};
      try {
        // this.$refs.cascader.clearValue(obj);
        this.choose_name = 0;
        this.choose_label = 0;
      } catch (err) {
        this.$refs.cascader.handleClear(obj);
        this.choose_name = [];
        this.choose_label = [];
      }
      // console.log("123");
      // !清除选择的节点数据
        this.values = [];
        this.contrast = []
    },
     handleSelectionExceeded() {
       message({
          showClose: true,
          message: this.$t(`message.Message.Upper`),
          type: 'warning',
          customClass: 'change_bar',
        })
    },
    // 多选框方法
    handleChange(e) {
      this.values = e;
      console.log(this.options);
      let arr = e; // 点击之后获取到的下标数组
      console.log(e, 'e');
      // let name = []; //选择的下标
      console.log(typeof arr);
      this.choose_name = [];
      this.choose_label = [];
      this.compare_id = [];
      // this.options_2
      arr.forEach((item) => {
        console.log(item, 'item');
        console.log(item[0]);
        console.log(this.options_2, 'this.options_2');
        console.log(this.options_2[item[0]].children[item[1] - 1]);
        console.log(this.options_2[item[0]].children[item[1] - 1].productid);
        this.choose_name.push(this.options_2[item[0]].children[item[1] - 1].label); // 点击产品名后 绑定的下标 (获取二位数组的数据)
        this.choose_label.push(this.options_2[item[0]].label); // 点击产品名后 绑定的下标 (获取二位数组的数据)
        console.log(this.choose_label);
        this.compare_id.push(this.options_2[item[0]].children[item[1] - 1].productid);
      });

      console.log(this.formInlines.product);
      console.log(this.choose_name);
      console.log(this.compare_id);
      if (this.compare_id.length > 10) {
        message({
          showClose: true,
          message: this.$t(`message.Message.Upper`),
          type: 'warning',
        });
        let num = this.compare_id.length - 10;
        this.delete_val(num);
      } else {
        this.req_Comparedatas();
      }
    },
    // 初始化页面时请求 用户的项目信息
    async req_UserProgram() {
      await HttpClient.post('program/programlist', {
        sub: this.$store.state.userid,
      }).then((res) => {
         this.program_name = res.list;
         this.program_name.filter((item)=>{
         if (item.category === "防晒霜") {
         item.category = 'Sun care';
         }
      })
        console.log(res);
      });

      console.log(this.program_name);
      this.req_UserProduct();
    },

    // 请求 项目对应的产品信息
    async req_UserProduct() {
      // 输出 选中的项目名字
      console.log('触发请求产品');
      console.log(this.choose_pj_name);

      // 寻找id
      for (let index = 0; index < this.program_name.length; index++) {
        if (
          this.choose_pj_name == this.program_name[index].project_name + '/' + this.program_name[index].category &&
          this.$route.params.length != undefined
        ) {
          //对比名字,拿到项目id
          this.project_id = this.program_name[index]._id;
        } else if (this.choose_pj_name == this.program_name[index].project_name) {
          this.project_id = this.program_name[index]._id;
        }
      }
      // 拿到项目id去请求
      await HttpClient.post('ctmproduct/productlist', {
        program: this.project_id, //项目id
      }).then((res) => {
        console.log(res);
        // console.log(this.project_id);
        // 拿到产品数组 list
        this.product_name = res.list;
      });
      console.log(this.product_name);
      // this.req_Paneldata();
      this.req_Product();
    },

    // 请求产品信息
    async req_Product() {
      for (let index = 0; index < this.product_name.length; index++) {
        if (this.choose_product == this.product_name[index].name) {
          this.product_id = this.product_name[index]._id;
        }
      }

      // 拿到产品id
      console.log(this.product_id);
      await HttpClient.post('result/findbyPanel', {
        Product: this.product_id, //产品id
      }).then((res) => {
        console.log(res);
        if (res.status == 0) {
          //
          this.req_info();
        } else if (res.status == 1) {
          // alert("status=1");
        } else if (res.status == 2) {
          this.err_message = res.message;
          this.err_messages();
        }
      });
    },
    //product info
    async req_info() {
      // 发送 请求产品信息
      await HttpClient.post('ctmproduct/productinfo', {
        product_id: this.product_id,
      }).then((res) => {
        console.log(res);
        this.product_message = res;
      });
      this.checked_product();
      this.req_compare_data();
      // !  这里获取到了数据  于是开始请求数据
      this.req_Attribute();
    },

    // 计算选择对比产品的数据
    checked_product() {
      this.options = [];
      this.options_model.value = '';
      this.options_model.label = '';
      console.log(this.choose_product);
      console.log(this.product_name);
      console.log(this.options_model);

      for (let index = 0; index < this.product_name.length; index++) {
        if (this.choose_product != this.product_name[index].name) {
          this.options_model.value = index;
          this.options_model.label = this.product_name[index].name;
          this.options.push(JSON.parse(JSON.stringify(this.options_model)));
          // console.log(this.options);
        }
      }
    },

    // 获取用户勾选的对比产品数据(没用)
    async req_Comparedata() {
      console.log(this.choose_name);
      console.log(this.product_name);
      this.compare_id = [];
      for (let i = 0; i < this.choose_name.length; i++) {
        for (let j = 0; j < this.product_name.length; j++) {
          if (this.choose_name[i] == this.product_name[j].name) {
            this.compare_id.push(JSON.parse(JSON.stringify(this.product_name[j]._id)));
          }
        }
      }
      console.log(this.compare_id);
      this.req_Comparedatas();
      // this.compare_i_message.length = 0;
    },
    // 获取用户勾选的对比产品数据
    async req_Comparedatas() {
      this.compare_i_message = [];
      for (let index = 0; index < this.compare_id.length; index++) {
        await HttpClient.post('ctmproduct/compareproductinfo', {
          productlist: [this.compare_id[index]],
        }).then((res) => {
          // this.compare_i_message.length = 0;
          console.log(res);
          this.resultArr = [];
          this.compare_i_message.push(res.productinfo[0]);
          console.log(this.compare_i_message);
          // ! 拿到产品的数据
          console.log('this.compare_i_messages数据', this.compare_i_message);
          // ! 取出每一个id进行排序  要有data  和重要度
          this.compare_i_message.forEach((message) => {
            const chaetmap_arr = message.paneldata.map((data, i) => ({
              data: data,
              importantdata: this.attribute_data.panel_attributes_weight[i],
              name: this.attribute_data.panel_attributes_item[i],
              name_en: this.attribute_data.panel_attributes_item_en[i],
            }));

            chaetmap_arr.sort((a, b) => b.importantdata - a.importantdata);
            this.resultArr.push(chaetmap_arr);
            
          });
        });
      }
      // this.setScrollBar();
      // window.addEventListener("resize", this.setScrollBar);
    },

    // 获取感官数据维度和重要性
    async req_Attribute() {
      // ! 先清除上一次的数据，防止数据叠加
      this.map_arr = [];
      this.sort_arr = [];
      this.count_arr = [];
      this.attribute_view = [];
      await HttpClient.post('attribute/panel_info', { language: this.$store.state.lang }).then((res) => {
        console.log(res);
        this.attribute_data = res;
        console.log('选择产品里面的输入框内容', this.formInlines.product);
        // 处理数据（将两个数据处理到一起）
        for (let index in res.panel_attributes_item) {
          this.count_arr.push({
            name: res.panel_attributes_item[index],
              name_en: this.attribute_data.panel_attributes_item_en[index],
            val: res.panel_attributes_weight[index],
          });
        }
        //! 根据重要度的值，判断等级
        this.sort_arr = this.count_arr;
        for (let index = 0; index < this.sort_arr.length; index++) {
          if (this.sort_arr[index].val >= 8) {
            this.attribute_view.push(0);
          } else if (this.sort_arr[index].val >= 5 && this.sort_arr[index].val < 8) {
            this.attribute_view.push(1);
          } else if (this.sort_arr[index].val >= 3 && this.sort_arr[index].val < 5) {
            this.attribute_view.push(2);
          } else if (this.sort_arr[index].val >= 1 && this.sort_arr[index].val < 3) {
            this.attribute_view.push(3);
          } else if (this.sort_arr[index].val >= 0 && this.sort_arr[index].val < 1) {
            this.attribute_view.push(4);
          } else if (this.sort_arr[index].val >= -1 && this.sort_arr[index].val < 0) {
            this.attribute_view.push(5);
          }
        }

        console.log('查看排序的顺序', this.sort_arr);
        console.log('attribute_view', this.attribute_view);
        // ! 根据重要度，把每一项加进数组里面
        let map_arr = [];
        for (let index in this.attribute_view) {
        // console.log(this.attribute_data.panel_attributes_item[index],"this.attribute_data.panel_attributes_item[index]");
        if (this.attribute_data.panel_attributes_item[index].indexOf('(') !== -1&&this.attribute_data.panel_attributes_item[index].indexOf(')') == -1) {
  this.attribute_data.panel_attributes_item[index] += ')';
}
  if (this.attribute_data.panel_attributes_item_en[index].indexOf('(') !== -1&&this.attribute_data.panel_attributes_item_en[index].indexOf(')') == -1) {
  this.attribute_data.panel_attributes_item_en[index] += ')';
}
        //  ! 截取到最后  中文
        let panel_attributes_item_end = []
          if(this.attribute_data.panel_attributes_item[index].includes("(")){
            const indexOfFirst = this.attribute_data.panel_attributes_item[index].indexOf("(");
              panel_attributes_item_end.push(this.attribute_data.panel_attributes_item[index].slice(indexOfFirst))
          }else{
            panel_attributes_item_end.push(" ")
          }
          // !开始  中文
          let panel_attributes_item_start = []
            if(this.attribute_data.panel_attributes_item[index].includes("(")){
            const indexOfFirst = this.attribute_data.panel_attributes_item[index].indexOf("(");
              panel_attributes_item_start.push(this.attribute_data.panel_attributes_item[index].slice(0,indexOfFirst))
          }else{
            panel_attributes_item_start.push(this.attribute_data.panel_attributes_item[index])
          }
           //  ! 截取到最后  英文
           let panel_attributes_item_en_end = []
          if(this.attribute_data.panel_attributes_item_en[index].includes("(")){
            const indexOfFirst = this.attribute_data.panel_attributes_item_en[index].indexOf("(");
              panel_attributes_item_en_end.push(this.attribute_data.panel_attributes_item_en[index].slice(indexOfFirst))
          }else{
            panel_attributes_item_en_end.push(" ")
          }
           // !开始  英文
          let panel_attributes_item_en_start = []
            if(this.attribute_data.panel_attributes_item_en[index].includes("(")){
            const indexOfFirst = this.attribute_data.panel_attributes_item_en[index].indexOf("(");
              panel_attributes_item_en_start.push(this.attribute_data.panel_attributes_item_en[index].slice(0,indexOfFirst))
          }else{
            panel_attributes_item_en_start.push(this.attribute_data.panel_attributes_item_en[index])
          }
          map_arr.push({
            importtant: this.attribute_view[index],
            name:panel_attributes_item_start,
            name_end:panel_attributes_item_end,
              name_en: panel_attributes_item_en_start,
              name_en_end:panel_attributes_item_en_end,
            importantdata: this.attribute_data.panel_attributes_weight[index],
            data: this.product_message.paneldata[index],
          });
             console.log(panel_attributes_item_start,"panel_attributes_item_start");
        }
     
        console.log('map_arr1', map_arr);
        console.log('attribute_view', this.attribute_view);
        // ! 进行排序即可
        for (let i = 0; i < map_arr.length; i++) {
          for (let j = 0; j < map_arr.length - i - 1; j++) {
            if (map_arr[j].importtant > map_arr[j + 1].importtant) {
              const temp = map_arr[j];
              map_arr[j] = map_arr[j + 1];
              map_arr[j + 1] = temp;
            } else if (map_arr[j].importtant == map_arr[j + 1].importtant) {
              if (map_arr[j].importantdata < map_arr[j + 1].importantdata) {
                const temp = map_arr[j];
                map_arr[j] = map_arr[j + 1];
                map_arr[j + 1] = temp;
              }
            }
          }
        }
        console.log('map_arr2', map_arr);
        this.map_arr = map_arr;
        this.dataArray = map_arr.map((obj) => obj.data);
      });
    },
    // 跳转回单品分析
    go_item() {
      this.$router.push({
        name: 'itemanalysis',
        params: {
          project_name: this.formInline.product,
          product_name: this.formInlines.product,
        },
      });
    },

    //获取对比产品数据
    async req_compare_data() {
      await HttpClient.post('program/compare_list', {
        user_id: this.$store.state.userid,
        product: this.product_id,
      }).then((res) => {
        console.log(res);
        this.options_2 = res.result;
        console.log(this.options_2);
      });
    },
  },
  mounted() {
    this.req_UserProgram();
    // this.req_Attribute();

    console.log(this.project_id);
    console.log(this.$route.params.project_name);
    console.log(this.$route.params.product_name);
    console.log(this.formInline.product);
    console.log(this.$route.params.program_id);
    this.project_id = this.$route.params.program_id;
    console.log(this.formInlines.product);
    this.project_id = this.$route.params.program_id;
  },
  created() {
    this.formInline.product = this.$route.params.project_name;
    this.formInlines.product = this.$route.params.product_name;
  },
};
</script>

<style>
div.tooltipStyle {
  opacity: 0.8;
}
</style>
<style lang="less" scoped>
@import url("../../style/Contrast/Contrast.less");
</style>
